import {SitePage} from 'app/layout';
import {graphql} from 'gatsby';
import {data, state} from 'app/types';
import {useEffect, useState} from "react";
import {QuestionSection} from "../app/survey/questions";
import {getTopicSuggestionTest} from "../app/topics/getTopicSuggestion";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, FormControlLabel,
  IconButton,
  Paper, Radio, RadioGroup, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";

type Survey = {
  _total: number;
  _completed: number;
  _step: number;
} & Record<string, any>;


type TestProps = {
  data: {
    topics: {
      nodes: Array<data.Topic>;
    };
    baseline: {
      nodes: Array<data.Survey>;
    };
    followup: {
      nodes: Array<data.Survey>;
    };
  };
};

// Keys to exclude from random generation
const excludedKeys: string[] = [
  "S1#intro",
  "S3#intro",
  "S3#intro-5-7",
  "S4#divider",
  "S4#intro",
  "S4#intro-1-5",
  "S4#note",
  "S5#intro",
  "S6#intro",
  "S7#intro",
  "S8#intro",
  "S9#intro-1",
  "S9#intro-2",
  "S10#divider",
  "S10#intro-1",
  "S10#intro-2",
  "AS-PSES#intro",
  "K6#intro",
  "K6#intro-2",
  "PSES-A#intro"
];

const topicDynamicPart2: string[] = [
  'pka-autonomy',
  'pka-everyday-emotions',
  'pka-tough-emotions'
]

const topicDynamicPart4: string[] = [
  'pka-connect',
  'pka-supportive-relationships',
  'pka-family-rules',
  'pka-health-habits',
  'pka-conflict',
  'pka-goals',
  'pka-seek-help'
]

const topicDynamicPart5: string[] = [
  'pka-fundamentals',
  'pka-sensory-needs'
]

const optionToValuePSES_A = (option: string) => {
  switch (option) {
    case 'Not at all confident':
      return {label: option, value: 1};
    case 'A little confident':
      return {label: option, value: 2};
    case 'Somewhat confident':
      return {label: option, value: 3};
    case 'Very confident':
      return {label: option, value: 4};
    default:
      return {label: option, value: option};
  }
};

const optionToValueAS_PSES = (option: string) => {
  switch (option) {
    case 'Strongly disagree':
      return {label: option, value: 1};
    case 'Disagree':
      return {label: option, value: 2};
    case 'Neutral':
      return {label: option, value: 3};
    case 'Agree':
      return {label: option, value: 4};
    case 'Strongly agree':
      return {label: option, value: 5};
    default:
      return {label: option, value: option};
  }
};

const optionToValueK6 = (option: string) => {
  switch (option) {
    case 'None of the time':
      return {label: option, value: 1};
    case 'A little of the time':
      return {label: option, value: 2};
    case 'Some of the time':
      return {label: option, value: 3};
    case 'Most of the time':
      return {label: option, value: 4};
    case 'All of the time':
      return {label: option, value: 5};
    default:
      return {label: option, value: option};
  }
};

const scoreBasedSection = (topicKey: string) => {
  switch (topicKey) {
    case "pka-affection-acceptance":
    case "pka-behaviour":
    case "pka-yourself":
      return "No Question based";
    case "pka-autonomy" :
      return "S2 : SortScore = (3 + sum(S2) / S2.length)";
    case "pka-everyday-emotions" :
      return "S7 : SortScore = (3 + sum(S7) / S7.length)";
    case "pka-tough-emotions" :
      return "S9 : SortScore = (3 + sum(S9) / S9.length)";
    case "pka-connect" :
      return "S1";
    case "pka-supportive-relationships" :
      return "S3";
    case "pka-family-rules" :
      return "S4";
    case "pka-health-habits" :
      return "S5";
    case "pka-conflict" :
      return "S6";
    case "pka-goals" :
      return "S8";
    case "pka-seek-help" :
      return "S10";
    case "pka-fundamentals" :
      return "PSES-A#Q11";
    case "pka-sensory-needs" :
      return "PSES-A#Q14";
  }
}


export default function Test(props: TestProps) {
  const {
    data: {
      topics: {nodes: topics},
      baseline: {nodes: baseline},
      followup: {nodes: followup},
    },
  } = props;


  const [sections, setSections] = useState<QuestionSection[]>([]);
  const [answers, setAnswers] = useState<{ [key: string]: string | number }>({});
  const [scores, setScores] = useState<{ [key: string]: string | number }>({});
  const [entries, setEntries] = useState<state.Survey[]>([]);


  useEffect(() => {
    if (baseline) {
      let sectionList: QuestionSection[] = [];
      for (let node of baseline) {
        const filteredSections = node.sections.data.filter(section => section.type !== 'paragraph');
        sectionList = sectionList.concat(filteredSections);
      }
      // Step 2: Get the second item from followup and add "SLEEP-QUESTIONS" section
      if (followup.length >= 2) {
        const secondFollowupItem = followup[1];
        const sleepQuestionsSection = secondFollowupItem.sections.data.find(
          (section: QuestionSection) => section.key === 'SLEEP-QUESTIONS'
        );
        if (sleepQuestionsSection) {
          sectionList.push(sleepQuestionsSection);
        }
      }
    // Step 3: Get the latest (last) item from followup and add it to section list
    const latestFollowupItem = followup[followup.length - 1];
    if (latestFollowupItem) {
      sectionList.push(...latestFollowupItem.sections.data);
    }
      console.log('firstNode', baseline);
      console.log('followup', followup);
      generateRandomAnswers(sectionList);
      console.log('sections', sectionList);
      setSections(sectionList);
    }
  }, [baseline]);

  const generateRandomAnswers = (sections: QuestionSection[]) => {
    const generatedAnswers: { [key: string]: string | number } = {};
    const generatedScores: { [key: string]: string | number } = {};

    sections.forEach((section) => {
      let sectionScore = 0;
      section.questions.forEach((question) => {
        const key = `${section.key}#${question.key}`;
        if (!excludedKeys.includes(key)) {
          let options = question.options || section.options;
          if (options && options.length > 0) {
            const randomIndex = Math.floor(Math.random() * options.length);
            const selectedOption = options[randomIndex];
            const optionLabel = typeof selectedOption === 'string' ? selectedOption : selectedOption.label;
            let value;
            let score: number;
            if (key.startsWith('PSES-A#')) {
              value = optionToValuePSES_A(optionLabel).value;
              score = Number(value);
            } else if (key.startsWith('AS-PSES#')) {
              value = optionToValueAS_PSES(optionLabel).value;
              score = Number(value);
            } else if (key.startsWith('K6#')) {
              value = optionToValueK6(optionLabel).value;
              score = Number(value);
            } else {
              value = optionLabel;
              score = isScoreExist(optionLabel, question.answers);
              if (section.key === 'S1') {
                // only last questions are considered
                if (section.key === 'S1' && ['Q1', 'Q2', 'Q3', 'Q4'].includes(question.key)) {
                  score = 0;
                }
              }
            }
            sectionScore += score;
            generatedAnswers[key] = value;
          }
        }
        generatedScores[section.key] = sectionScore;
      });
    });
    setAnswers(generatedAnswers);
    setScores(generatedScores);
  };

  useEffect(() => {
    if (answers) {
      const survey: Survey = {
        _total: 100,
        _completed: 80,
        _step: 2,
        ...answers
      };
      console.log('survey', survey)
      const entries = getTopicSuggestionTest(survey);
      console.log('entries', entries)
      setEntries(entries);
    }
  }, [sections, answers]);

  const getMaxOptions = (section) => {
    let optionsSet = new Set();
    if (section.options) {
      section.options.forEach((option) => {
        optionsSet.add(typeof option === 'string' ? option : option.label);
      });
    }
    section.questions.forEach((question) => {
      if (question.options) {
        question.options.forEach((option) => {
          optionsSet.add(typeof option === 'string' ? option : option.label);
        });
      }
    });
    return Array.from(optionsSet);
  };

  const cellStyle = {
    maxWidth: '150px',
    wordWrap: 'break-word',
    fontSize: '12px'
  };

  const dividerStyle = {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  };

  const isScoreExist = (selectedAnswer, answers) => {
    return answers && answers.includes(selectedAnswer) ? 1 : 0;
  };

  const getQuestionSizeBySection = (section: QuestionSection) => {
    let questions = [];
    for (let i = 0; i < section.questions.length; i++) {
      if (section.questions[i].type !== 'paragraph') {
        questions.push(section.questions[i]);
      }
    }
    return questions.length;
  }

  const sectionReview = (section: QuestionSection) => {
    const questionSize = getQuestionSizeBySection(section);
    return (
      <div>
        <Typography variant="body2">Section : {section.title}</Typography>
        <Typography variant="body2">Key : {section.key}</Typography>
        <Typography variant="body2">Total Score : {scores[section.key]}</Typography>
        <Typography variant="body2">Total Question : {questionSize}</Typography>
        {section.key !== 'S1' ? (
          <Typography variant="body2">Total Score Rate (TotalScore/TotalQuestionLength)
            : {scores[section.key] / questionSize}</Typography>
        ) : (
          <Typography variant="body2">Total Score Rate (TotalScore Last 4 question /TotalQuestionLength)
            : {scores[section.key] / 4}</Typography>
        )}
      </div>
    )
  }


  const TopicList = () => {
    const getBackgroundColor = (topicKey) => {
      if (topicDynamicPart2.includes(topicKey)) {
        return '#d9ead3';
      } else if (topicDynamicPart4.includes(topicKey)) {
        return '#fce5cd';
      } else if (topicDynamicPart5.includes(topicKey)) {
        return '#e6b8af';
      }
      return '#FFFFFF';
    };

    return (
      <div>
        {entries.map((topic, index) => {
          return (
            <Accordion key={index} style={{backgroundColor: getBackgroundColor(topic.topicKey)}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={topic.recommend}
                      disabled={topic.pin}
                      name={topic.topicKey}
                    />
                  }
                  label={topic.topicKey}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Paper style={{padding: '10px', width: '100%'}}>
                  <Typography variant="body2" style={{marginBottom: '8px'}}>
                    Topic Key: {topic.topicKey}
                  </Typography>
                  <Typography variant="body2" style={{marginBottom: '8px'}}>
                    Sort Score: {topic.sortScore}
                  </Typography>
                  <Typography variant="body2" style={{marginBottom: '8px'}}>
                    Score based on : {scoreBasedSection(topic.topicKey)}
                  </Typography>
                </Paper>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    );
  };

  const findParagraph = (section: QuestionSection) => {
    let paragraphArray = section.questions.filter(question => question.type === 'paragraph');
    console.log('findParagraph', paragraphArray)
    // return paragraphArray[0].content
  }

  return (
    <SitePage>
      <Accordion key={'questions'}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h6">Section Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {sections.map((section) => {
            const tableOptions = getMaxOptions(section);
            return (
              <Accordion key={section.key}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                  {sectionReview(section)}
                </AccordionSummary>
                <AccordionDetails>
                  <div key={section.key}>
                    {/*{findParagraph(section)}*/}
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{...cellStyle, ...dividerStyle}}>Question</TableCell>
                            {tableOptions.map((option, index) => {
                              const optionLabel = typeof option === 'string' ? option : option.label;
                              return (
                                <TableCell key={index} align="center"
                                           style={{...cellStyle, ...dividerStyle}}>{optionLabel}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {section.questions.map((question) => {
                            if (question.type === 'paragraph') {
                              // return (
                              //   <TableRow key={question.key}>
                              //     <Typography variant="body2" style={{color: 'red'}}>{question.content}</Typography>
                              //   </TableRow>
                              // )
                            } else {
                              const questionOptions = question.options || section.options;
                              const tooltipText = question.answers ? `Score will be given if selected: ${question.answers.join(', ')}` : "";
                              return (
                                <TableRow key={question.key}>
                                  <TableCell style={{...cellStyle, ...dividerStyle}}>
                                    {question.label || question.content}
                                    {question.answers && (
                                      <Tooltip title={tooltipText} arrow>
                                        <IconButton size="small">
                                          <QuestionMarkIcon fontSize="small"/>
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  {tableOptions.map((option, index) => {
                                    const optionLabel = typeof option === 'string' ? option : option.label;
                                    const key = `${section.key}#${question.key}`;
                                    let isMatch = answers[key] === optionLabel || answers[key] === optionLabel;
                                    let score = isScoreExist(optionLabel, question.answers);
                                    let isDisabledQuestions = false;

                                    if (key.startsWith('PSES-A#')) {
                                      let displayKey = optionToValuePSES_A(optionLabel).value;
                                      isMatch = answers[key] === displayKey;
                                      score = Number.parseFloat(displayKey);
                                    } else if (key.startsWith('AS-PSES#')) {
                                      let displayKey = optionToValueAS_PSES(optionLabel).value;
                                      isMatch = answers[key] === displayKey;
                                      score = Number.parseFloat(displayKey);
                                    } else if (key.startsWith('K6#')) {
                                      let displayKey = optionToValueK6(optionLabel).value;
                                      isMatch = answers[key] === displayKey;
                                      score = Number.parseFloat(displayKey);
                                    }
                                    if (section.key === 'S1' && ['Q1', 'Q2', 'Q3', 'Q4'].includes(question.key)) {
                                      isDisabledQuestions = true;
                                    }
                                    return (
                                      <TableCell key={index} align="center"
                                                 style={{
                                                   ...cellStyle, ...dividerStyle,
                                                   backgroundColor: isDisabledQuestions ? 'lightslategrey' : 'white',
                                                   color: isMatch ? 'green' : 'black'
                                                 }}>
                                        {isMatch ? (
                                          <>
                                            ✔
                                            {score ? <div>Score: {score}</div> : ''}
                                          </>
                                        ) : ''}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            }

                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </AccordionDetails>
      </Accordion>

      <Accordion key={'topics'}>

        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Typography variant="h6">Topic Recommendations and Topics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {entries.length > 0 && (
            <TopicList/>
          )}
        </AccordionDetails>

      </Accordion>

    </SitePage>
  );
}

export const query = graphql`
  query Test($project: String = "pip-ka") {
    topics: allStrapiTopic(filter: { project: { name: { eq: $project } } }) {
      nodes {
        ...TestTopicFragment
      }
    }
    baseline: allStrapiSurvey(
      filter: { project: { name: { eq: $project } }, scope: { eq: "baseline" } }
      sort: { fields: seq }
    ) {
      nodes {
        ...TestSurveyFragment
      }
    }

    followup: allStrapiSurvey(
      filter: { project: { name: { eq: $project } }, scope: { eq: "followup" } }
      sort: { fields: seq }
    ) {
      nodes {
        ...TestSurveyFragment
      }
    }
  }

  fragment TestTopicFragment on STRAPI_TOPIC {
    key: slug
    seq
    title
    description
    cover {
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.25, height: 800)
        }
      }
    }
    contents {
      key: slug
      seq
      title
      optional
      goals
      activityKeys {
        data: strapi_json_value
      }
    }
  }

  fragment TestSurveyFragment on STRAPI_SURVEY {
    key
    seq
    scope
    title
    tint
    cover {
      localFile {
        publicURL
      }
    }
    extraStyle {
      left
      bottom
      height
    }
    sections {
      data: strapi_json_value {
        key
        title
        type
        scales
        options
        required
        questions {
          key
          label
          type
          scales
          options
          answers
          variant
          content
          cond
          required
          title
        }
      }
    }
  }
`;


